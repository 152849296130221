import React, { useEffect } from 'react';
import HeaderShared from '../../../shared/Header/header.shared';
import ProcessStatusComponent from '../../../components/ProcessStatus/processStatus.component';
import FooterShared from '../../../shared/Header/footer.shared';
import Step6Component from '../../../components/Process/Step6/step6.component';
import PrivacyComponent from '../../../components/Banners/Privacy/privacy.component';
import { IUser } from '../../../types/userInterface';

interface OpinionScreenProps {
  user: Partial<IUser>;
  token: string;
}

const OpinionScreen: React.FC<OpinionScreenProps> = ({ user, token }) => {
  const steps = [
    {
      title: 'Cuéntanos de ti',
      description: 'Perfil completado',
      status: 'status--success',
      completed: true,
      color: 'green300',
    },
    {
      title: 'Te presentamos a tu acompañante',
      description: 'Completado',
      status: 'status--success',
      completed: true,
      color: 'green300',
    },
    {
      title: 'Te invitamos al taller',
      description: 'Revisa catálogo',
      status: 'status--success',
      completed: true,
      color: 'green300',
    },
    {
      title: 'Seguimos en contacto',
      description: 'Completado',
      status: 'status--success',
      completed: true,
      color: 'green300',
    },
    {
      title: 'Déjanos tu opinión',
      description: 'En proceso',
      status: 'status--process',
      completed: false,
      color: 'purple500',
    },

    // Status color
    //-- In progress status--process - purple500
    //-- In progress status--success - green300
    //-- In progress status neutral '' - gray600
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
    console.log(user);
  }, []);

  return (
    <>
      <HeaderShared user={user} />
      <section className="module container">
        <div className="body-row row">
          <div className="col-12">
            <div className="module-bottom row">
              <div className="col-12">
                <PrivacyComponent />
              </div>
            </div>
            <div className="module-bottom40 row">
              <div className="col-12">
                <ProcessStatusComponent steps={steps} currentStepIndex={0} />
              </div>
            </div>
            <div className="row">
              <div className="col-12 text-center module-bottom40">
                <h3 className="h2 mb-3">Déjanos tu opinión</h3>
                <p className="h2 text-500">Tu opinión nos ayuda a mejorar</p>
                <p>Retroalimenta a las amigas que estuvieron en tu proceso</p>
              </div>
              <Step6Component user={user} token={token} />
            </div>
          </div>
        </div>
      </section>
      <FooterShared />
    </>
  );
};

export default OpinionScreen;
