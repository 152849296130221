import React, { useState, useEffect } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import DeleteAccountComponent from '../../Modals/DeleteAccountModal/deleteAccountModal.component';
import DeleteConfirmComponent from '../../Modals/DeleteConfirmModal/deleteConfirmModal.component';
import { IUser } from '../../../types/userInterface';
import LoaderComponent from '../../Loader/loader.component';
import { getCurrentAuthenticatedUser } from '../../../utils/utils';
import { fetchStrapiDocs } from '../../../api/strapi';
import { ITooltip, strapiDoc } from '../../../types/strapiDocs';
import { updateUser } from '../../../api/usersApi';
import Markdown from 'react-markdown';

interface Props {
  tooltips: ITooltip[];
}

const SocioCulturalComponent = ({ tooltips }: Props) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [token, setToken] = useState('');
  const [user, setUser] = useState<Partial<IUser>>({});
  const [formularioValido, setFormularioValido] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [modalShowConfirm, setModalShowConfirm] = useState(false);
  const [ocupaciones, setOcupaciones] = useState<strapiDoc[]>([]);
  const [nivelesEducativos, setNivelesEducativos] = useState<strapiDoc[]>([]);
  const [pueblosOrigen, setPueblosOrigen] = useState<strapiDoc[]>([]);

  const setError = (message: string) => {
    console.log(message);
    Swal.fire({
      icon: 'error',
      title: 'Oops...',
      text: message,
    });
  };

  const handlePuebloOriginarioChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const isOriginTown = e.target.value === 'si';
    setUser({
      ...user,
      contextoSociocultural: { ...user.contextoSociocultural, isOriginTown },
    });
  };

  const verifyFields = () => {
    const camposRequeridos = document.querySelectorAll(
      '.requiredField'
    ) as NodeListOf<HTMLInputElement | HTMLSelectElement>;
    let todosCompletos = true;
    const gruposRadio = new Set();

    camposRequeridos.forEach((campo) => {
      if (campo.type === 'radio' || campo.type === 'checkbox') {
        gruposRadio.add(campo.name);
      } else {
        if (campo.value === undefined || campo.value === null) {
          todosCompletos = false;
        } else {
          const campoCompleto = campo.value.trim() !== '';
          todosCompletos = todosCompletos && campoCompleto;
        }
      }
    });

    gruposRadio.forEach((nombre) => {
      const seleccionado =
        document.querySelector(`input[name="${nombre}"]:checked`) !== null;
      todosCompletos = todosCompletos && seleccionado;
    });

    setFormularioValido(todosCompletos);
  };

  const handleDelete = () => {
    // Cierra el primer modal y muestra el segundo modal
    setModalShow(false);
    setModalShowConfirm(true);
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setLoading(true);
    try {
      const currentUser = {
        ...user,
      };
      setUser(currentUser);
      await updateUser(user._id || '', { ...currentUser, step: 6 }, token);
      navigate('/salud-ginecologica');
    } catch (error) {
      setError(`${error}`);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const currentUser = await getCurrentAuthenticatedUser();
        if (!currentUser) {
          navigate('/');
        }
        setToken(currentUser?.jwtToken || '');
        setUser(currentUser?.amigasUser || {});
        const query = { sort: 'nombre:asc' };
        const ocupacionesData = await fetchStrapiDocs('ocupaciones', query);
        const nivelesEducativosData = await fetchStrapiDocs(
          'niveles-educativos',
          query
        );
        const pueblosOrigenData = await fetchStrapiDocs(
          'pueblos-origen',
          query
        );
        setOcupaciones(ocupacionesData.data);
        setNivelesEducativos(nivelesEducativosData.data);
        setPueblosOrigen(pueblosOrigenData.data);
        verifyFields();
      } catch (error) {
        setError(`${error}`);
      } finally {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    verifyFields();
  }, [user]);

  return (
    <>
      <DeleteAccountComponent
        show={modalShow}
        onHide={() => setModalShow(false)}
        onDelete={handleDelete}
        setLoading={setLoading}
      />
      <DeleteConfirmComponent
        show={modalShowConfirm}
        onHide={() => setModalShowConfirm(false)}
      />
      <div className="form-block">
        {loading && <LoaderComponent />}
        <form onSubmit={handleSubmit}>
          <div className="form-row">
            <label className="form-label label--icon">
              Ocupación principal <small className="required">*</small>
              <OverlayTrigger
                placement="bottom"
                overlay={
                  <Tooltip>
                    <Markdown allowedElements={[]} unwrapDisallowed={true}>
                      {`${
                        tooltips.find(({ field }) => field === 'Ocupacion')
                          ?.content || ''
                      }`}
                    </Markdown>
                  </Tooltip>
                }
              >
                <span className="material-icons-outlined icon">
                  help_outline
                </span>
              </OverlayTrigger>
            </label>
            <select
              className="form-select requiredField"
              aria-label="Tu ocupación"
              defaultValue={'default'}
              name="Ocupacion"
              value={user.contextoSociocultural?.principalJob || ''}
              onChange={(e) => {
                setUser({
                  ...user,
                  contextoSociocultural: {
                    ...user.contextoSociocultural,
                    principalJob: e.target.value,
                  },
                });
              }}
            >
              <option value="default" disabled>
                Elige una de las opciones
              </option>
              {ocupaciones.map((ocupacion) => (
                <option key={ocupacion.id} value={ocupacion.attributes.nombre}>
                  {ocupacion.attributes.nombre}
                </option>
              ))}
            </select>
          </div>
          <div className="form-row">
            <label className="form-label label--icon">
              Ocupación secundaria (opcional)
              <OverlayTrigger
                placement="bottom"
                overlay={
                  <Tooltip>
                    <Markdown allowedElements={[]} unwrapDisallowed={true}>
                      {`${
                        tooltips.find(
                          ({ field }) => field === 'OcupacionSecundaria'
                        )?.content || ''
                      }`}
                    </Markdown>
                  </Tooltip>
                }
              >
                <span className="material-icons-outlined icon">
                  help_outline
                </span>
              </OverlayTrigger>
            </label>
            <select
              className="form-select"
              aria-label="Tu Ocupación secundaria"
              defaultValue={'default'}
              name="OcupacionSecundaria"
              value={user.contextoSociocultural?.secondaryJob || ''}
              onChange={(e) => {
                setUser({
                  ...user,
                  contextoSociocultural: {
                    ...user.contextoSociocultural,
                    secondaryJob: e.target.value,
                  },
                });
              }}
            >
              <option value="default" disabled>
                Elige una de las opciones
              </option>
              {ocupaciones.map((ocupacion) => (
                <option key={ocupacion.id} value={ocupacion.attributes.nombre}>
                  {ocupacion.attributes.nombre}
                </option>
              ))}
            </select>
          </div>
          <div className="form-row">
            <label className="form-label label--icon">
              Ocupación adicional (opcional)
              <OverlayTrigger
                placement="bottom"
                overlay={
                  <Tooltip>
                    <Markdown allowedElements={[]} unwrapDisallowed={true}>
                      {`${
                        tooltips.find(
                          ({ field }) => field === 'OcupacionAdicional'
                        )?.content || ''
                      }`}
                    </Markdown>
                  </Tooltip>
                }
              >
                <span className="material-icons-outlined icon">
                  help_outline
                </span>
              </OverlayTrigger>
            </label>
            <select
              className="form-select"
              aria-label="Tu ocupación adicional"
              defaultValue={'default'}
              name="OcupacionAdicional"
              value={user.contextoSociocultural?.additionalJob || ''}
              onChange={(e) => {
                setUser({
                  ...user,
                  contextoSociocultural: {
                    ...user.contextoSociocultural,
                    additionalJob: e.target.value,
                  },
                });
              }}
            >
              <option value="default" disabled>
                Elige una de las opciones
              </option>
              {ocupaciones.map((ocupacion) => (
                <option key={ocupacion.id} value={ocupacion.attributes.nombre}>
                  {ocupacion.attributes.nombre}
                </option>
              ))}
            </select>
          </div>
          <div className="form-row">
            <label className="form-label label--icon">
              Tu nivel educativo <small className="required">*</small>
            </label>
            <select
              className="form-select requiredField"
              aria-label="Tu nivel educativo"
              defaultValue={'default'}
              name="NivelEducativo"
              value={user.contextoSociocultural?.educationLevel || ''}
              onChange={(e) => {
                setUser({
                  ...user,
                  contextoSociocultural: {
                    ...user.contextoSociocultural,
                    educationLevel: e.target.value,
                  },
                });
              }}
            >
              <option value="default" disabled>
                Elige una de las opciones
              </option>
              {nivelesEducativos.map((nivel) => (
                <optgroup key={nivel.id} label={nivel.attributes.nombre}>
                  {['en curso', 'completa', 'incompleta'].map((opcion, id) => (
                    <option
                      key={id}
                      value={`${nivel.attributes.nombre} ${opcion}`}
                    >
                      {`${nivel.attributes.nombre} ${opcion}`}
                    </option>
                  ))}
                </optgroup>
              ))}
            </select>
          </div>
          <div className="form-row">
            <label className="form-label label--icon">
              ¿Eres de algún pueblo originario?
              <OverlayTrigger
                placement="bottom"
                overlay={
                  <Tooltip>
                    <Markdown allowedElements={[]} unwrapDisallowed={true}>
                      {`${
                        tooltips.find(({ field }) => field === 'pueblo')
                          ?.content || ''
                      }`}
                    </Markdown>
                  </Tooltip>
                }
              >
                <span className="material-icons-outlined icon">
                  help_outline
                </span>
              </OverlayTrigger>
            </label>
            <div className="form-check-multi">
              {[
                {
                  id: 'puebloSi',
                  value: 'si',
                  label: 'Sí',
                  checked: user.contextoSociocultural?.isOriginTown,
                },
                {
                  id: 'puebloNo',
                  value: 'no',
                  label: 'No',
                  checked: !user.contextoSociocultural?.isOriginTown,
                },
                {
                  id: 'PuebloNoSeguro',
                  value: 'no-estoy-segura',
                  label: 'No estoy segura',
                  checked:
                    user.contextoSociocultural?.isOriginTown === undefined,
                },
              ].map(({ id, value, label, checked }) => (
                <div className="form-check" key={id}>
                  <input
                    className="form-check-input"
                    type="radio"
                    name="pueblo"
                    id={id}
                    value={value}
                    checked={checked}
                    onChange={handlePuebloOriginarioChange}
                  />
                  <label className="form-check-label" htmlFor={id}>
                    {label}
                  </label>
                </div>
              ))}
            </div>
          </div>
          {user.contextoSociocultural?.isOriginTown && (
            <div className="form-row">
              <label className="form-label label--icon">
                Selecciona tu pueblo de origen
              </label>
              <select
                className="form-select"
                aria-label="Tu pueblo origen"
                defaultValue={'default'}
                name="PuebloOrigen"
                value={user.contextoSociocultural?.originTown}
                onChange={(e) => {
                  setUser({
                    ...user,
                    contextoSociocultural: {
                      ...user.contextoSociocultural,
                      originTown: e.target.value,
                    },
                  });
                }}
              >
                <option value="default" disabled>
                  Elige una de las opciones
                </option>
                {pueblosOrigen.map((pueblo) => (
                  <option key={pueblo.id} value={pueblo.attributes.nombre}>
                    {pueblo.attributes.nombre}
                  </option>
                ))}
                <option value="otro">Otro</option>
              </select>
            </div>
          )}
          <div className="form-row">
            <label className="form-label label--icon">
              ¿Eres de origen afrodescendiente?{' '}
              <small className="required">*</small>
              <OverlayTrigger
                placement="bottom"
                overlay={
                  <Tooltip>
                    <Markdown allowedElements={[]} unwrapDisallowed={true}>
                      {`${
                        tooltips.find(
                          ({ field }) => field === 'afrodescendiente'
                        )?.content || ''
                      }`}
                    </Markdown>
                  </Tooltip>
                }
              >
                <span className="material-icons-outlined icon">
                  help_outline
                </span>
              </OverlayTrigger>
            </label>
            <div className="form-check-multi">
              {[
                {
                  id: 'afroSi',
                  value: 'si',
                  label: 'Sí',
                  checked: user.contextoSociocultural?.afroDescendant === 'si',
                },
                {
                  id: 'afroNo',
                  value: 'no',
                  label: 'No',
                  checked: user.contextoSociocultural?.afroDescendant === 'no',
                },
                {
                  id: 'afroNoSeguro',
                  value: 'no-estoy-segura',
                  label: 'No estoy segura',
                  checked:
                    user.contextoSociocultural?.afroDescendant ===
                    'no-estoy-segura',
                },
              ].map(({ id, value, label, checked }) => (
                <div className="form-check" key={id}>
                  <input
                    className="form-check-input requiredField"
                    type="radio"
                    name="afrodescendiente"
                    id={id}
                    value={value}
                    checked={checked}
                    onChange={(e) => {
                      setUser({
                        ...user,
                        contextoSociocultural: {
                          ...user.contextoSociocultural,
                          afroDescendant: e.target.value,
                        },
                      });
                    }}
                  />
                  <label className="form-check-label" htmlFor={id}>
                    {label}
                  </label>
                </div>
              ))}
            </div>
          </div>
          <div className="form-row">
            <label className="form-label label--icon">
              ¿Con quién vives? <small className="required">*</small>
            </label>
            <input
              type="text"
              className="form-control requiredField"
              id="live"
              name="PersonasCompartir"
              placeholder="Escribe aquí con quién compartes tu vivienda "
              value={user.contextoSociocultural?.liveWith || ''}
              onChange={(e) => {
                setUser({
                  ...user,
                  contextoSociocultural: {
                    ...user.contextoSociocultural,
                    liveWith: e.target.value,
                  },
                });
              }}
            />
          </div>
          <div className="form-row module-top40 form--last">
            <button
              type="submit"
              className="btn btn--type1"
              disabled={!formularioValido}
            >
              Guardar y continuar
            </button>
          </div>
          <div className="form-row form--last">
            <button
              type="button"
              className="btn--danger"
              onClick={() => setModalShow(true)}
            >
              Borrar mi registro
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default SocioCulturalComponent;
