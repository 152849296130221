import React, { useState } from 'react';
import StopProcessComponent from '../../Modals/StopProcessModal/stopProcessModal.component';
import StopConfirmComponent from '../../Modals/StopConfirmModal/stopConfirmModal.component';

const Step4Component: React.FC = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [modalShow, setModalShow] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [modalShowConfirm, setModalShowConfirm] = useState(false);
  const handleDelete = () => {
    // Cierra el primer modal y muestra el segundo modal
    setModalShow(false);
    setModalShowConfirm(true);
  };
  return (
    <>
      <StopProcessComponent
        show={modalShow}
        onHide={() => setModalShow(false)}
        onDelete={handleDelete}
      />
      <StopConfirmComponent
        show={modalShowConfirm}
        onHide={() => setModalShowConfirm(false)}
      />
      <div className="col-12">
        <h3 className="h2 text-400">Llena tu bitácora para la entrega</h3>
        <div className="alert alert--simple-check col-md-8 col-12">
          <span className="icon material-icons-outlined">check_circle</span>
          <span className="text-20">
            <strong>
              Tener tu bitácora te ayudará a tener listo todo lo que necesitas
              para llevar a cabo tu procedimiento de forma segura.
            </strong>
          </span>
        </div>
        <article>
          <ul className="text-20">
            <li className="mb-3">
              Recuerda que puedes comunicarte con tu acompañante, antes durante
              y después procedimiento.
            </li>
            <li>
              Esperamos que te sientas segura y acompañada en este proceso.
            </li>
          </ul>
        </article>
      </div>
      <div className="col-lg-5 col-12">
        <div className="form-row module-top40 form--last">
          <a href="/bitacora" className="btn btn--type1">
            Llenar bitácora para entregadora
          </a>
        </div>
        <div className="form-row form--last">
          <button
            type="button"
            className="btn btn--type2"
            onClick={() => setModalShow(true)}
          >
            Ya no necesito este acompañamiento
          </button>
        </div>
      </div>
    </>
  );
};

export default Step4Component;
