import React from 'react';
import HeaderShared from '../../../shared/Header/header.shared';
import ProcessStatusComponent from '../../../components/ProcessStatus/processStatus.component';
import FooterShared from '../../../shared/Header/footer.shared';
import Step5Component from '../../../components/Process/Step5/step5.component';
import PrivacyComponent from '../../../components/Banners/Privacy/privacy.component';
import { IUser } from '../../../types/userInterface';

interface BitacoraScreenProps {
  user: Partial<IUser>;
}

const BitacoraScreen: React.FC<BitacoraScreenProps> = ({ user }) => {
  const steps = [
    {
      title: 'Cuéntanos de ti',
      description: 'Perfil completado',
      status: 'status--success',
      completed: true,
      color: 'green300',
    },
    {
      title: 'Te presentamos a tu acompañante',
      description: 'Completado',
      status: 'status--success',
      completed: true,
      color: 'green300',
    },
    {
      title: 'Te invitamos al taller',
      description: 'Revisa catálogo',
      status: 'status--success',
      completed: true,
      color: 'green300',
    },
    {
      title: 'Seguimos en contacto',
      description: 'Completado',
      status: 'status--success',
      completed: true,
      color: 'green300',
    },
    {
      title: 'Déjanos tu opinión',
      description: 'En progreso',
      status: 'status--process',
      completed: false,
      color: 'purple500',
    },
  ];

  return (
    <>
      <HeaderShared user={user} />
      <section className="module container">
        <div className="body-row row">
          <div className="col-12">
            <div className="module-bottom row">
              <div className="col-12">
                <PrivacyComponent />
              </div>
            </div>
            <div className="module-bottom row">
              <div className="col-12">
                <ProcessStatusComponent steps={steps} currentStepIndex={0} />
              </div>
              <Step5Component />
            </div>
          </div>
        </div>
      </section>
      <FooterShared />
    </>
  );
};

export default BitacoraScreen;
