import React, { useState, useEffect } from 'react';
import { Alert, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import LoaderComponent from '../../Loader/loader.component';
import { fetchStrapiSingle } from '../../../api/strapi';
import { strapiDoc } from '../../../types/strapiDocs';
import {
  IPostDeliveryStatus,
  IUser,
  UserSteps,
} from '../../../types/userInterface';
import Markdown from 'react-markdown';
import { getCurrentAuthenticatedUser } from '../../../utils/utils';
import { updateUser } from '../../../api/usersApi';

const Step5Component: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState<Partial<IUser>>({});
  const [token, setToken] = useState<string>('');
  const [pageContent, setPageContent] = useState<strapiDoc>();
  const [postDeliveryStatus, setPostDeliveryStatus] = useState<
    IPostDeliveryStatus[]
  >([]);
  const [formularioValido, setFormularioValido] = useState(false);
  const [atencionMedicaShow, setAtencionMedicaShow] = useState(false);

  const navigate = useNavigate();

  const setError = (mensaje: string) => {
    Swal.fire({
      icon: 'error',
      title: 'Oops...',
      text: mensaje,
    });
  };

  const verifyFields = () => {
    const camposRequeridos = document.querySelectorAll(
      '.requiredField'
    ) as NodeListOf<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>;
    let todosCompletos = true;

    camposRequeridos.forEach((campo) => {
      if (campo.type === 'radio' || campo.type === 'checkbox') {
        // Verificar si al menos uno del grupo está seleccionado
        const grupoSeleccionado = !!document.querySelector(
          `input[name="${campo.name}"]:checked`
        );
        todosCompletos = todosCompletos && grupoSeleccionado;
      } else {
        const campoCompleto = campo.value.trim() !== '';
        todosCompletos = todosCompletos && campoCompleto;
      }
    });

    setFormularioValido(todosCompletos);
  };

  const fetchUser = async (prePostDeliveryStatus: IPostDeliveryStatus[]) => {
    try {
      const user = await getCurrentAuthenticatedUser();
      const amigasUser = user?.amigasUser || {};
      const { postDeliveryStatus } = amigasUser;

      if (postDeliveryStatus && postDeliveryStatus.length > 0) {
        setPostDeliveryStatus(postDeliveryStatus);
      } else {
        setPostDeliveryStatus(prePostDeliveryStatus);
      }

      setUser(user?.amigasUser || {});
      setToken(user?.jwtToken || '');
    } catch (error) {
      setError('Error al obtener el usuario');
    }
  };

  const fetchContent = async () => {
    try {
      setLoading(true);
      const response = await fetchStrapiSingle('/bitacora-post-procedimiento', {
        populate: '*',
      });

      setPageContent(response.data);
      const { questions } = response.data.attributes;
      if (questions && questions.length > 0) {
        const deliveryQuestions: IPostDeliveryStatus[] = questions.map(
          ({ id, Nombre }) => ({
            question: {
              documentId: `${id}`,
              value: Nombre,
            },
            answer: '',
          })
        );
        deliveryQuestions.push(
          ...[
            {
              question: {
                documentId: 'atencion-medica',
                value: 'Registra la fecha de la atención médica',
              },
              answer: '',
            },
            {
              question: {
                documentId: 'atencion-medica-tipo',
                value: '¿Qué tipo de atención médica recibiste?',
              },
              answer: '',
            },
          ]
        );
        await fetchUser(deliveryQuestions);
      }
    } catch (error) {
      setError('Error al cargar el contenido');
    } finally {
      setLoading(false);
    }
  };

  const handleUpdateUser = async (id: string, user: Partial<IUser>) => {
    try {
      setLoading(true);
      const response = await updateUser(id, user, token);
      if (response) {
        Swal.fire({
          icon: 'success',
          title: '¡Éxito!',
          text: 'Gracias, hemos recibido tu información.',
        }).then(() => {
          navigate('/dejanos-tu-opinion');
        });
      } else {
        setError('Error al actualizar el usuario');
      }
    } catch (error) {
      setError('Error al actualizar el usuario');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchContent();
  }, []);

  useEffect(() => {
    verifyFields();
  }, [postDeliveryStatus]);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    handleUpdateUser(user._id || '', {
      postDeliveryStatus: postDeliveryStatus || [],
      step: UserSteps.PENDING_EVALUATION,
    });
  };

  const { header, subheader, content, alert, questions, medicalAttentionList } =
    pageContent?.attributes || {};

  return (
    <>
      {loading && <LoaderComponent />}
      <div className="col-12 text-center">
        <h3 className="text-52 text-500 mb-3">
          Seguimiento post procedimiento
        </h3>
        <p className="text-24">Queremos saber cómo te sientes</p>
      </div>
      <div className="row">
        <div className="col-md-5 col-12">
          <article className="text-20">
            <h2 className="text-green900 text-regular mb-3">
              {header || ''}
              <br /> {subheader || ''}
            </h2>
            <Markdown>{content || ''}</Markdown>
          </article>
          <div className="form-block">
            <form onSubmit={handleSubmit}>
              {(questions || [])
                .filter(({ type }) => type === 'text')
                .map((question) => {
                  const { id, Nombre, tooltip, requiredField } = question;
                  const value = postDeliveryStatus.find(
                    (item) => item.question.documentId === `${id}`
                  )?.answer;
                  return (
                    <div className="form-row" key={id}>
                      <label className="form-label label--icon">
                        {Nombre}{' '}
                        {requiredField && <small className="required">*</small>}
                        {tooltip && (
                          <OverlayTrigger
                            placement="bottom"
                            overlay={<Tooltip>{tooltip}</Tooltip>}
                          >
                            <span className="material-icons-outlined icon">
                              help_outline
                            </span>
                          </OverlayTrigger>
                        )}
                      </label>
                      <input
                        type="text"
                        className={`form-control ${
                          requiredField ? 'requiredField' : ''
                        }`}
                        placeholder={Nombre}
                        value={value || ''}
                        onChange={(e) => {
                          const newStatus = postDeliveryStatus.map((item) => {
                            if (item.question.documentId === `${id}`) {
                              return {
                                ...item,
                                answer: e.target.value,
                              };
                            }
                            return item;
                          });
                          setPostDeliveryStatus(newStatus);
                        }}
                      />
                    </div>
                  );
                })}
              {(questions || [])
                .filter(({ type }) => type === 'radio')
                .map((question) => {
                  const { id, Nombre, tooltip, requiredField } = question;
                  const value = postDeliveryStatus.find(
                    (item) => item.question.documentId === `${id}`
                  )?.answer;
                  return (
                    <div className="form-row" key={id}>
                      <label className="form-label label--icon">
                        {Nombre}{' '}
                        {requiredField && <small className="required">*</small>}
                        {tooltip && (
                          <OverlayTrigger
                            placement="bottom"
                            overlay={<Tooltip>{tooltip}</Tooltip>}
                          >
                            <span className="material-icons-outlined icon">
                              help_outline
                            </span>
                          </OverlayTrigger>
                        )}
                      </label>
                      <div className="form-check-multi">
                        {['Sí', 'No'].map((option) => (
                          <div className="form-check" key={option}>
                            <input
                              className={`form-check-input ${
                                requiredField ? 'requiredField' : ''
                              }`}
                              type="radio"
                              name={`question-${id}`}
                              id={`option-${option}`}
                              value={option}
                              checked={value === option}
                              onChange={(e) => {
                                const newStatus = postDeliveryStatus.map(
                                  (item) => {
                                    if (item.question.documentId === `${id}`) {
                                      return {
                                        ...item,
                                        answer: e.target.value,
                                      };
                                    }
                                    return item;
                                  }
                                );
                                setPostDeliveryStatus(newStatus);
                                setAtencionMedicaShow(option === 'Sí');
                              }}
                            />
                            <label
                              className="form-check-label"
                              htmlFor={`question-${id}`}
                            >
                              {option}
                            </label>
                          </div>
                        ))}
                      </div>
                    </div>
                  );
                })}
              {atencionMedicaShow && (
                <>
                  <div className="form-row">
                    <label className="form-label label--icon">
                      Registra la fecha de la atención médica
                    </label>
                    <input
                      type="date"
                      className="form-control"
                      name="atencion-medica"
                      placeholder="Selecciona la fecha aquí"
                      value={
                        postDeliveryStatus.find(
                          (item) =>
                            item.question.documentId === 'atencion-medica'
                        )?.answer
                      }
                      onChange={(e) => {
                        const newStatus = postDeliveryStatus.map((item) => {
                          if (item.question.documentId === 'atencion-medica') {
                            return {
                              ...item,
                              answer: e.target.value,
                            };
                          }
                          return item;
                        });
                        setPostDeliveryStatus(newStatus);
                      }}
                    />
                  </div>
                  <div className="form-row">
                    <label className="form-label label--icon">
                      ¿Qué tipo de atención médica recibiste?
                    </label>
                    <select
                      className="form-select"
                      aria-label="¿Qué tipo de atención médica recibiste?"
                      defaultValue={'default'}
                      name="atencion-medica-tipo"
                      value={
                        postDeliveryStatus.find(
                          (item) =>
                            item.question.documentId === 'atencion-medica-tipo'
                        )?.answer
                      }
                      onChange={(e) => {
                        const newStatus = postDeliveryStatus.map((item) => {
                          if (
                            item.question.documentId === 'atencion-medica-tipo'
                          ) {
                            return {
                              ...item,
                              answer: e.target.value,
                            };
                          }
                          return item;
                        });
                        setPostDeliveryStatus(newStatus);
                      }}
                    >
                      <option value="default">Elige una de las opciones</option>
                      {(medicalAttentionList || []).map((atencion) => (
                        <option key={atencion.id} value={atencion.content}>
                          {atencion.content}
                        </option>
                      ))}
                    </select>
                  </div>
                </>
              )}
              <div className="form-row">
                <Alert variant="success" className="alert--soft-success">
                  <i className="icon fa-regular fa-flag"></i>
                  <article>
                    <Markdown
                      allowedElements={['p', 'strong', 'a']}
                      unwrapDisallowed={false}
                      components={{
                        p: ({ children }) => <p className="mb-3">{children}</p>,
                        strong: ({ children }) => <strong>{children}</strong>,
                        a: ({ children, href }) => (
                          <div className="col-12 col-reset d-flex justify-content-center mt-4">
                            <a
                              href={href}
                              target="_blank"
                              rel="noreferrer"
                              className="btn--simple text-purple500"
                            >
                              {children}
                            </a>
                          </div>
                        ),
                      }}
                    >
                      {alert || ''}
                    </Markdown>
                  </article>
                </Alert>
              </div>
              {(questions || [])
                .filter(({ type }) => type === 'textarea')
                .map((question) => {
                  const { id, Nombre, tooltip, requiredField } = question;
                  const answer = postDeliveryStatus.find(
                    (item) => item.question.documentId === `${id}`
                  )?.answer;
                  return (
                    <div className="form-row" key={id}>
                      <label className="form-label" key={id}>
                        {Nombre}
                        {''}
                        {requiredField && <small className="required">*</small>}
                      </label>
                      <textarea
                        className={`form-control ${
                          requiredField ? 'requiredField' : ''
                        }`}
                        placeholder={tooltip}
                        id="emociones"
                        value={answer}
                        onChange={(e) => {
                          const newStatus = postDeliveryStatus.map((item) => {
                            if (item.question.documentId === `${id}`) {
                              return {
                                ...item,
                                answer: e.target.value,
                              };
                            }
                            return item;
                          });
                          setPostDeliveryStatus(newStatus);
                        }}
                      ></textarea>
                    </div>
                  );
                })}
              <div className="form-row  form--last">
                <button
                  type="submit"
                  className="btn btn--type1"
                  disabled={!formularioValido}
                >
                  Guardar y continuar
                </button>
              </div>
            </form>
          </div>
        </div>
        <aside className="auto-row__right col-md-7 col-12">
          <figure className="image-400">
            <img src="/assets/images/illustrations/women.png" alt="Women" />
          </figure>
        </aside>
      </div>
    </>
  );
};

export default Step5Component;
