import React from 'react';
import HeaderShared from '../../../../shared/Header/header.shared';
import ProcessStatusComponent from '../../../../components/ProcessStatus/processStatus.component';
import FooterShared from '../../../../shared/Header/footer.shared';
import DonateComponent from '../../../../components/Banners/Donate/donate.component';
import Step4Component from '../../../../components/Process/Step4/step4.component';
import PrivacyComponent from '../../../../components/Banners/Privacy/privacy.component';
import { IUser } from '../../../../types/userInterface';

interface EntregaSuccessScreenProps {
  user: Partial<IUser>;
}

const EntregaSuccessScreen: React.FC<EntregaSuccessScreenProps> = ({
  user,
}) => {
  const steps = [
    {
      title: 'Cuéntanos de ti',
      description: 'Perfil completado',
      status: 'status--success',
      completed: true,
      color: 'green300',
    },
    {
      title: 'Te presentamos a tu acompañante',
      description: 'Completado',
      status: 'status--success',
      completed: true,
      color: 'green300',
    },
    {
      title: 'Te invitamos al taller',
      description: 'Revisa catálogo',
      status: 'status--process',
      completed: false,
      color: 'purple500',
    },
    {
      title: 'Seguimos en contacto',
      description: 'Pendiente',
      status: '',
      completed: false,
      color: 'gray600',
    },
    {
      title: 'Déjanos tu opinión',
      description: 'Pendiente',
      status: '',
      completed: false,
      color: 'gray600',
    },

    // Status color
    //-- In progress status--process - purple500
    //-- In progress status--success - green300
    //-- In progress status neutral '' - gray600
  ];

  return (
    <>
      <HeaderShared user={user} />
      <section className="module container">
        <div className="body-row row">
          <div className="col-12">
            <div className="module-bottom row">
              <div className="col-12">
                <PrivacyComponent />
              </div>
            </div>
            <div className="module-bottom row">
              <div className="col-12">
                <h1 className="text-64 mb-3">Hola, {user.username || ''}</h1>
                <p className="text-32 mb-0">
                  <strong>No. {user.userId || ''}</strong>
                </p>
              </div>
            </div>
            <div className="module-bottom row">
              <div className="col-12">
                <h2 className="h1">Tu proceso</h2>
                <ProcessStatusComponent steps={steps} currentStepIndex={0} />
              </div>
              <Step4Component />
            </div>
            <div className="row">
              <div className="col-12">
                <DonateComponent />
              </div>
            </div>
          </div>
        </div>
      </section>
      <FooterShared />
    </>
  );
};

export default EntregaSuccessScreen;
