import React, { useState, useEffect } from 'react';
import Swal from 'sweetalert2';
import RatingComponent from '../../Form/Rating/rating.component';
import { useNavigate } from 'react-router-dom';
import DonateModalComponent from '../../Modals/Donate/donate.component';
import { IUser, UserSteps } from '../../../types/userInterface';
import { fetchStrapiDocs } from '../../../api/strapi';
import LoaderComponent from '../../Loader/loader.component';
import { IAdmin, IAdminEvaluation } from '../../../types/adminInterface';
import { IWorkshop } from '../../../types/workshopInterface';
import { updateUser, userAdminEvaluation } from '../../../api/usersApi';

type Step6Props = {
  user: Partial<IUser>;
  token: string;
};

const Step6Component: React.FC<Step6Props> = ({ user, token }) => {
  const [loading, setLoading] = useState(false);
  const [evaluateContent, setEvaluateContent] = useState<{
    [x: string]: IAdminEvaluation;
  }>();
  const [isFormValid, setIsFormValid] = useState<boolean>(false);
  const [modalShow, setModalShow] = useState(false);

  const navigate = useNavigate();

  const setError = (mensaje: string) => {
    Swal.fire({
      icon: 'error',
      title: 'Oops...',
      text: mensaje,
    });
  };

  const fetchContent = async () => {
    try {
      setLoading(true);
      const response = await fetchStrapiDocs('/admin-temas-evaluacion', {
        populate: '*',
        sort: 'rol',
      });
      const categories = Array.from(
        new Set(response.data.map(({ attributes: { rol } }) => rol || ''))
      );
      const evaluateContent: {
        [x: string]: IAdminEvaluation;
      } = {};
      categories.forEach((c) => {
        let adminId = '';
        const { assignedTo, assignedDeliverer, workshopSelected } = user;
        switch (c) {
          case 'Acompañante':
            adminId = (assignedTo?.adminId as IAdmin)?._id || '';
            break;
          case 'Tallerista':
            adminId =
              ((workshopSelected?.workshopId as IWorkshop)
                ?.adminId as string) || '';
            break;
          case 'Entregadora':
            adminId = (assignedDeliverer?.adminId as IAdmin)?._id || '';
            break;
          default:
            break;
        }
        evaluateContent[c] = {
          adminId,
          userId: user._id || '',
          adminEvaluationTopics: response.data
            .filter(({ attributes: { rol } }) => rol === c)
            .map(({ attributes: { tema } }) => ({
              topic: tema || '',
              score: 0,
            })),
          observations: '',
        } as IAdminEvaluation;
      });

      setEvaluateContent(evaluateContent);
    } catch (error) {
      setError('Error al cargar el contenido');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchContent();
  }, []);

  useEffect(() => {
    setIsFormValid(
      Object.values(evaluateContent || {}).every(
        (evaluation) =>
          evaluation.adminEvaluationTopics.every((topic) => topic.score > 0) &&
          evaluation.observations
      )
    );
  }, [evaluateContent]);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      setLoading(true);
      const adminEvaluations = Object.values(evaluateContent || {}).map(
        (evaluation) => evaluation
      );
      const responses = await Promise.all(
        adminEvaluations.map(async (evaluation) => {
          const response = await userAdminEvaluation(evaluation, token);
          return response;
        })
      );
      const userUpdateResponse = await updateUser(
        user._id || '',
        {
          step: UserSteps.PROCESS_COMPLETE,
        },
        token
      );
      if (!userUpdateResponse || responses.some((response) => !response)) {
        setError('Error al enviar el formulario');
        return;
      }
      navigate('/opinion-completada');
    } catch (error) {
      setError('Error al enviar el formulario');
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {loading && <LoaderComponent />}
      <DonateModalComponent
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
      <div className="form-block">
        <form onSubmit={handleSubmit}>
          {evaluateContent &&
            Object.keys(evaluateContent).map((category, index) => {
              const adminEvaluationTopics =
                evaluateContent[category].adminEvaluationTopics;
              return (
                <div className="form-big-row" key={index}>
                  <div className="rate-row row">
                    <div className="rate-col__left col-md-4">
                      <div className="card">
                        <div className="chatbox-action__user mb-4">
                          <figure>
                            <img
                              src="/assets/images/icons/user-pic-2.svg"
                              alt="Acompañante"
                            />
                          </figure>
                          <article>
                            <h4>{category}</h4>
                          </article>
                        </div>
                        {adminEvaluationTopics.map((evaluationTopic, i) => {
                          return (
                            <div className="form-row" key={i}>
                              <label className="form-label">
                                <strong>{evaluationTopic.topic}</strong>
                              </label>
                              <RatingComponent
                                onRating={(score) => {
                                  evaluationTopic.score = score;
                                  setEvaluateContent({
                                    ...evaluateContent,
                                  });
                                }}
                              />
                            </div>
                          );
                        })}
                      </div>
                    </div>
                    <aside className="col-md-7 offset-md-1 col-12">
                      <h3 className="h2">¿Cómo fue tu experiencia?</h3>
                      <p className="text-24">
                        <strong>Comparte una retroalimentación</strong>
                      </p>
                      <div className="form-row">
                        <label className="form-label">
                          Puedes contarnos lo que te gustó, te funcionó o lo que
                          no te agradó dentro de todo el proceso.
                          <small className="required">*</small>
                        </label>
                        <textarea
                          className="form-control requiredField"
                          placeholder="Escribe tu opinión aquí"
                          id="feedback"
                          value={evaluateContent[category].observations || ''}
                          onChange={(e) => {
                            const value = e.target.value;
                            evaluateContent[category].observations = value;
                            setEvaluateContent({
                              ...evaluateContent,
                            });
                          }}
                        ></textarea>
                      </div>
                    </aside>
                  </div>
                </div>
              );
            })}
          <div className="form-big-row big-row--last row d-flex justify-content-center">
            <button
              type="button"
              className="btn btn--type2 btn--270"
              onClick={() => setModalShow(true)}
            >
              Hacer donativo
            </button>
            <button
              type="submit"
              className="btn btn--type1 btn--270"
              disabled={!isFormValid}
            >
              Subir evaluación
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default Step6Component;
