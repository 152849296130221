import React, { useEffect, useState } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Amplify } from 'aws-amplify';
import './App.css';
import LoginScreen from './pages/Auth/Login/login.screen';
import ForgotPasswordScreen from './pages/Auth/ForgotPassword/forgotPassword.screen';
import UpdatePasswordScreen from './pages/Auth/UpdatePassword/updatePassword.screen';
import SignUpScreen from './pages/Auth/SignUp/signUp.screen';
import PhoneScreen from './pages/Auth/Phone/phone.screen';
import ActiveCodeScreen from './pages/Auth/ActiveCode/activeCode.screen';
import EmailCodeScreen from './pages/Auth/ActiveCode/codeEmail/codeEmail.screen';
import PhoneCodeScreen from './pages/Auth/ActiveCode/codePhone/codePhone.screen';
import SocioDemographicsScreen from './pages/Auth/SocioDemographics/socioDemographics.screen';
import CausalesScreen from './pages/Auth/Causales/causales.screen';
import SocioCulturalScreen from './pages/Auth/SocioCultural/socioCultural.screen';
import SaludGinecologicaScreen from './pages/Auth/SaludGinecologica/saludGinecologica.screen';
import SaludEmocionesScreen from './pages/Auth/SaludEmociones/saludEmociones.screen';
import SexualidadEmocionesScreen from './pages/Auth/SexualidadEmociones/sexualidadEmociones.screen';
import ViolentometroScreen from './pages/Auth/Violentometro/violentometro.screen';
import IncidenciasScreen from './pages/Auth/Incidencias/incidencias.screen';
import ProcessScreen from './pages/Dashboard/Process/process.component';
import DonateScreen from './pages/Donate/Index/index.screen';
import DonateCardScreen from './pages/Donate/Card/card.screen';
import DonateThanksScreen from './pages/Donate/Thanks/thanks.screen';
import DonateThanksDepositScreen from './pages/Donate/ThanksDeposit/thanksDeposit.screen';
import ChatAcompananteScreen from './pages/Dashboard/Chat/ChatAcompanante/chatboxAcompanante.screen';
import AcompanamientoScreen from './pages/Dashboard/Acompanamiento/acompanamiento.screen';
import TallerScreen from './pages/Dashboard/Taller/taller.screen';
import TalleresScreen from './pages/Dashboard/Taller/Talleres/talleres.screen';
import ChatGrupalScreen from './pages/Dashboard/Chat/ChatGrupal/chatboxGrupal.screen';
import EntregaScreen from './pages/Dashboard/Entrega/entrega.screen';
import ChatEntregaScreen from './pages/Dashboard/Chat/ChatEntrega/chatboxEntrega.screen';
import EntregaSuccessScreen from './pages/Dashboard/Entrega/EntregaSucess/entregaSucess.screen';
import BitacoraScreen from './pages/Dashboard/Bitacora/bitacora.screen';
import FinalizarScreen from './pages/Dashboard/Finalizar/finalizar.screen';
import OpinionScreen from './pages/Dashboard/Opinion/opinion.screen';
import FinalizarOpinionScreen from './pages/Dashboard/Opinion/Thanks/thanks.screen';
import ScrollToTop from './components/ScollToTop/scrollToTop.component';
import config from './amplifyconfiguration.json';
import { getCurrentUser } from 'aws-amplify/auth';
import { IUser, UserSteps } from './types/userInterface';
import { getCurrentAuthenticatedUser } from './utils/utils';
import LoaderComponent from './components/Loader/loader.component';

Amplify.configure(config);

function App() {
  const [hasLogin, setHasLogin] = useState(false);
  const [user, setUser] = useState<Partial<IUser>>({});
  const [isLoading, setIsLoading] = useState(false);
  const [token, setToken] = useState('');

  const mainRoute = (user: Partial<IUser>) => {
    if (!hasLogin) {
      return <LoginScreen />;
    }
    const { step } = user;
    switch (step) {
      case UserSteps.PROCEDIMIENTO_COMPLETO:
        return <FinalizarScreen user={user} />;
      case UserSteps.INFORMACION_DE_CONTACTO:
        return <PhoneScreen user={user} />;
      case UserSteps.CODIGO_DE_ACTIVACION:
        return <ActiveCodeScreen user={user} />;
      case UserSteps.DATOS_SOCIODEMOGRÁFICOS:
        return <SocioDemographicsScreen user={user} />;
      case UserSteps.CAUSALES:
        return <CausalesScreen />;
      case UserSteps.CONTEXTO_SOCIOCULTURAL:
        return <SocioCulturalScreen user={user} />;
      case UserSteps.SALUD_GINECOLOGICA:
        return <SaludGinecologicaScreen user={user} />;
      case UserSteps.SALUD_EMOCIONES:
        return <SaludEmocionesScreen user={user} />;
      case UserSteps.SEXUALIDAD_EMOCIONES:
        return <SexualidadEmocionesScreen user={user} />;
      case UserSteps.VIOLENTOMETRO:
        return <ViolentometroScreen user={user} />;
      case UserSteps.INCIDENCIAS:
        return <IncidenciasScreen user={user} />;
      case UserSteps.MI_PROCESO:
        return <ProcessScreen />;
      case UserSteps.ACOMPANAMIENTO:
        return <AcompanamientoScreen />;
      case UserSteps.TALLER:
        return <TallerScreen />;
      case UserSteps.ENTREGA:
        return <EntregaScreen />;
      case UserSteps.ENTREGA_SUCCESS:
        return <EntregaSuccessScreen user={user} />;
      case UserSteps.PENDING_EVALUATION:
        return <OpinionScreen user={user} token={token} />;
      case UserSteps.PROCESS_COMPLETE:
        return <FinalizarScreen user={user} />;
      default:
        return <ProcessScreen />;
    }
  };

  useEffect(() => {
    const checkLogin = async () => {
      try {
        setIsLoading(true);
        const currentUser = await getCurrentUser();
        if (currentUser) {
          setHasLogin(true);
          const user = await getCurrentAuthenticatedUser();
          if (user) {
            setUser(user.amigasUser);
            setToken(user.jwtToken);
          }
        }
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoading(false);
      }
    };
    checkLogin();
  }, []);

  useEffect(() => {}, [user]);

  return (
    <main className="app-main">
      {!isLoading ? (
        <BrowserRouter>
          <ScrollToTop>
            <Routes>
              <Route path="/" element={mainRoute(user)} />
              <Route
                path="/recuperar-contrasena"
                element={<ForgotPasswordScreen />}
              />
              <Route
                path="/actualizar-contrasena"
                element={<UpdatePasswordScreen />}
              />
              <Route path="/crear-cuenta" element={<SignUpScreen />} />
              <Route
                path="/informacion-de-contacto"
                element={<PhoneScreen user={user} />}
              />
              <Route
                path="/codigo-de-activacion"
                element={<ActiveCodeScreen user={user} />}
              />
              <Route path="/codigo-email" element={<EmailCodeScreen />} />
              <Route path="/codigo-telefonico" element={<PhoneCodeScreen />} />
              <Route
                path="/datos-sociodemograficos"
                element={<SocioDemographicsScreen user={user} />}
              />
              <Route path="/causales" element={<CausalesScreen />} />
              <Route
                path="/contexto-sociocultural"
                element={<SocioCulturalScreen user={user} />}
              />
              <Route
                path="/salud-ginecologica"
                element={<SaludGinecologicaScreen user={user} />}
              />
              <Route
                path="/salud-y-emociones"
                element={<SaludEmocionesScreen user={user} />}
              />
              <Route
                path="/sexualidad-y-emociones"
                element={<SexualidadEmocionesScreen user={user} />}
              />
              <Route
                path="/violentometro"
                element={<ViolentometroScreen user={user} />}
              />
              <Route
                path="/incidencias"
                element={<IncidenciasScreen user={user} />}
              />
              <Route path="/mi-proceso" element={<ProcessScreen />} />
              <Route
                path="/acompanamiento"
                element={<AcompanamientoScreen />}
              />
              <Route path="/taller" element={<TallerScreen />} />
              <Route path="/entrega" element={<EntregaScreen />} />
              <Route
                path="/entrega-success"
                element={<EntregaSuccessScreen user={user} />}
              />
              <Route path="/talleres" element={<TalleresScreen />} />
              <Route
                path="/bitacora"
                element={<BitacoraScreen user={user} />}
              />
              <Route path="/donativo" element={<DonateScreen />} />
              <Route path="/donativo-tarjeta" element={<DonateCardScreen />} />
              <Route
                path="/donativo-gracias"
                element={<DonateThanksScreen />}
              />
              <Route
                path="/donativo-deposito"
                element={<DonateThanksDepositScreen />}
              />
              <Route
                path="/chat-acompanante"
                element={<ChatAcompananteScreen user={user} token={token} />}
              />
              <Route
                path="/chat-entrega"
                element={<ChatEntregaScreen user={user} token={token} />}
              />
              <Route path="/chat-grupal/:id" element={<ChatGrupalScreen />} />
              <Route
                path="/procedimiento-completo"
                element={<FinalizarScreen user={user} />}
              />
              <Route
                path="/dejanos-tu-opinion"
                element={<OpinionScreen user={user} token={token} />}
              />
              <Route
                path="/opinion-completada"
                element={<FinalizarOpinionScreen />}
              />
            </Routes>
          </ScrollToTop>
        </BrowserRouter>
      ) : (
        <LoaderComponent />
      )}
    </main>
  );
}

export default App;
