import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import DeleteAccountComponent from '../../Modals/DeleteAccountModal/deleteAccountModal.component';
import DeleteConfirmComponent from '../../Modals/DeleteConfirmModal/deleteConfirmModal.component';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { IUser } from '../../../types/userInterface';
import { strapiDoc } from '../../../types/strapiDocs';
import { fetchStrapiDocs } from '../../../api/strapi';
import { getCurrentAuthenticatedUser } from '../../../utils/utils';
import LoaderComponent from '../../Loader/loader.component';
import { updateUser } from '../../../api/usersApi';
import { StrapiDoc } from '../../../types/userInterface';

const SocioDemographicsComponent = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [token, setToken] = useState('');
  const [user, setUser] = useState<Partial<IUser>>({
    datosSociodemograficos: {
      country: { id: 4, name: 'Chile' },
    },
  });
  const [countryList, setCountryList] = useState<strapiDoc[]>([]);
  const [stateList, setStateList] = useState<strapiDoc[]>([]);
  const [cityList, setCityList] = useState<strapiDoc[]>([]);
  const [validForm, setValidForm] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [modalShowConfirm, setModalShowConfirm] = useState(false);

  const handleAgeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newAge = e.target.value;
    setUser({
      ...user,
      datosSociodemograficos: {
        ...user.datosSociodemograficos,
        currentAge: Number(newAge) || 0,
      },
    });
  };

  const handleCountryChange = async (
    e: React.ChangeEvent<HTMLSelectElement>
  ) => {
    setStateList([]);
    setCityList([]);
    const countryId = Number(e.target.value);
    const foundedCountry = countryList.find(({ id }) => id === countryId);
    if (!foundedCountry) {
      setUser({
        ...user,
        datosSociodemograficos: {
          ...user.datosSociodemograficos,
          country: { id: countryId, name: 'Otro' },
        },
      });
    } else {
      const {
        id,
        attributes: { nombre },
      } = foundedCountry;
      setUser({
        ...user,
        datosSociodemograficos: {
          ...user.datosSociodemograficos,
          country: { id, name: nombre },
        },
      });
    }
    // Fetch states
    const query = {
      sort: ['nombre:asc'],
      filters: { pais: { $eq: countryId } },
      pagination: { pageSize: 100 },
      populate: '*',
    };
    const states = await fetchStrapiDocs('/estados', query);
    setStateList(states.data);
  };

  const handleStateChange = async (e: React.ChangeEvent<HTMLSelectElement>) => {
    setCityList([]);
    const stateId = Number(e.target.value);
    const foundedState = stateList.find(({ id }) => id === stateId);
    if (foundedState) {
      const {
        id,
        attributes: { nombre, colectivas },
      } = foundedState;
      const colectiva: StrapiDoc = {};
      if (colectivas && colectivas.data.length > 0) {
        colectiva.documentId = `${colectivas.data[0].id}`;
        colectiva.value = colectivas.data[0].attributes.nombre;
      }
      setUser({
        ...user,
        datosSociodemograficos: {
          ...user.datosSociodemograficos,
          state: { id, name: nombre },
        },
        colectiva,
      });
    }
    // Fetch cities
    const query = {
      sort: ['nombre:asc'],
      filters: { estado: { $eq: stateId } },
      pagination: { pageSize: 100 },
      populate: '*',
    };
    const cities = await fetchStrapiDocs('/ciudades', query);
    setCityList(cities.data);
  };

  const handleCityChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const cityId = Number(e.target.value);
    const foundedCity = cityList.find(({ id }) => id === cityId);
    if (foundedCity) {
      const {
        id,
        attributes: { nombre },
      } = foundedCity;
      setUser({
        ...user,
        datosSociodemograficos: {
          ...user.datosSociodemograficos,
          city: { id, name: nombre },
        },
      });
    }
  };

  const handleKeyPress = (e: React.KeyboardEvent) => {
    // Permitir solo números
    if (!/[0-9]/.test(e.key)) {
      e.preventDefault();
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);
    await updateUser(
      user._id || '',
      {
        ...user,
        step: 4,
      },
      token
    );
    setTimeout(() => {
      navigate('/causales');
      setLoading(false);
    }, 100);
  };

  const handleDelete = () => {
    // Cierra el primer modal y muestra el segundo modal
    setModalShow(false);
    setModalShowConfirm(true);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch countries
        const query = { sort: ['nombre:asc'] };
        const countries = await fetchStrapiDocs('/paises', query);
        const data = countries.data;
        data.push({ id: 0, attributes: { nombre: 'Otro' } });
        setCountryList(countries.data);

        const currentUser = await getCurrentAuthenticatedUser();
        if (currentUser) {
          setUser({
            ...user,
            ...currentUser.amigasUser,
          });
          setToken(currentUser.jwtToken);
          // Fetch states
          const query = {
            sort: ['nombre:asc'],
            filters: {
              pais: { $eq: user.datosSociodemograficos?.country?.id },
            },
            pagination: { pageSize: 100 },
            populate: '*',
          };
          const states = await fetchStrapiDocs('/estados', query);
          setStateList(states.data);
        }
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    if (
      user.datosSociodemograficos?.currentAge &&
      user.datosSociodemograficos?.country?.id === 0 &&
      user.datosSociodemograficos?.country?.name !== 'Otro'
    ) {
      setValidForm(true);
      return;
    }
    if (
      user.datosSociodemograficos?.currentAge &&
      user.datosSociodemograficos?.country?.id &&
      user.datosSociodemograficos?.state?.id
    ) {
      setValidForm(true);
    } else {
      setValidForm(false);
    }
  });

  return (
    <>
      <DeleteAccountComponent
        show={modalShow}
        onHide={() => setModalShow(false)}
        onDelete={handleDelete}
        setLoading={setLoading}
      />
      <DeleteConfirmComponent
        show={modalShowConfirm}
        onHide={() => setModalShowConfirm(false)}
      />
      <div className="form-block">
        {loading && <LoaderComponent />}
        <form onSubmit={handleSubmit}>
          <div className="form-row">
            <label htmlFor="age" className="form-label label--icon">
              Tu edad actual <small className="required">*</small>
              <OverlayTrigger
                placement="bottom"
                overlay={
                  <Tooltip>
                    Es importante para nosotras generar estadísticas generales
                    sobre quiénes abortan y en que etapa de sus vidas lo
                    hicieron. Estas estadísticas ayudan a despenalizar
                    socialmente a quienes abortan.
                  </Tooltip>
                }
              >
                <span className="material-icons-outlined icon">
                  help_outline
                </span>
              </OverlayTrigger>
            </label>
            <input
              type="text"
              className="form-control"
              id="age"
              placeholder="Ingresa tu edad actual"
              value={user.datosSociodemograficos?.currentAge || ''}
              onChange={handleAgeChange}
              maxLength={2}
              pattern="\d*"
              onKeyPress={handleKeyPress}
            />
          </div>
          <div className="form-row">
            <label htmlFor="nation" className="form-label label--icon">
              Tu nacionalidad <small className="required">*</small>
              <OverlayTrigger
                placement="bottom"
                overlay={
                  <Tooltip>
                    Es importante para nosotras generar estadísticas generales
                    sobre quiénes abortan y cuáles son sus países de origen.
                    Estas estadísticas ayudan a despenalizar socialmente a
                    quienes abortan.
                  </Tooltip>
                }
              >
                <span className="material-icons-outlined icon">
                  help_outline
                </span>
              </OverlayTrigger>
            </label>
            <select
              className="form-select"
              aria-label="Tu nacionalidad"
              onChange={handleCountryChange}
              value={user.datosSociodemograficos?.country?.id}
            >
              <option value="default" disabled>
                Elige una de las opciones
              </option>
              {countryList.map((country) => (
                <option key={country.id} value={country.id}>
                  {country.attributes.nombre}
                </option>
              ))}
            </select>
          </div>
          {user.datosSociodemograficos?.country?.id === 0 && (
            <div className="form-row">
              <label htmlFor="age" className="form-label label--icon">
                Ingresa tu nacionalidad
              </label>
              <input
                type="text"
                className="form-control"
                id="otherNation"
                onChange={(e) => {
                  setUser({
                    ...user,
                    datosSociodemograficos: {
                      ...user.datosSociodemograficos,
                      country: { id: 0, name: e.target.value },
                    },
                  });
                }}
              />
            </div>
          )}
          {stateList.length > 0 && (
            <div className="form-row">
              <label htmlFor="region" className="form-label label--icon">
                Tu región <small className="required">*</small>
                <OverlayTrigger
                  placement="bottom"
                  overlay={
                    <Tooltip>
                      Es importante para nosotras saber en cuál territorio te
                      ubicas, con el objetivo de brindarte información que
                      manejemos respecto a servicios de salud disponibles en tu
                      territorio.
                    </Tooltip>
                  }
                >
                  <span className="material-icons-outlined icon">
                    help_outline
                  </span>
                </OverlayTrigger>
              </label>
              <select
                className="form-select"
                aria-label="Tu región"
                onChange={handleStateChange}
                defaultValue={'default'}
              >
                <option value="default" disabled>
                  Elige una de las opciones
                </option>
                {stateList.map((state, index) => (
                  <option key={index} value={state.id}>
                    {state.attributes.nombre}
                  </option>
                ))}
              </select>
            </div>
          )}
          {cityList.length > 0 && (
            <div className="form-row">
              <label htmlFor="comuna" className="form-label label--icon">
                Tu comuna <small className="required">*</small>
                <OverlayTrigger
                  placement="bottom"
                  overlay={
                    <Tooltip>
                      Es importante para nosotras saber en cuál territorio te
                      ubicas, con el objetivo de brindarte información que
                      manejemos respecto a servicios de salud disponibles en tu
                      territorio.
                    </Tooltip>
                  }
                >
                  <span className="material-icons-outlined icon">
                    help_outline
                  </span>
                </OverlayTrigger>
              </label>
              <select
                className="form-select"
                aria-label="Tu comuna"
                onChange={handleCityChange}
                defaultValue={'default'}
              >
                <option value="default" disabled>
                  Elige una de las opciones
                </option>
                {cityList.map((city, index) => (
                  <option key={index} value={city.id}>
                    {city.attributes.nombre}
                  </option>
                ))}
              </select>
            </div>
          )}
          <div className="form-row module-top40 form--last">
            <button
              type="submit"
              className="btn btn--type1"
              disabled={!validForm}
            >
              Guardar y continuar
            </button>
          </div>
          <div className="form-row form--last">
            <button
              type="button"
              className="btn--danger"
              onClick={() => setModalShow(true)}
            >
              Borrar mi registro
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default SocioDemographicsComponent;
