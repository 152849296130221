import React from 'react';
import HeaderShared from '../../../shared/Header/header.shared';
import ProcessStatusComponent from '../../../components/ProcessStatus/processStatus.component';
import FooterShared from '../../../shared/Header/footer.shared';
import DonateComponent from '../../../components/Banners/Donate/donate.component';
import { IUser, UserSteps } from '../../../types/userInterface';
import { Link } from 'react-router-dom';

interface FinalizarScreenProps {
  user: Partial<IUser>;
}

const FinalizarScreen: React.FC<FinalizarScreenProps> = ({ user }) => {
  const steps = [
    {
      title: 'Cuéntanos de ti',
      description: 'Perfil completado',
      status: 'status--success',
      completed: true,
      color: 'green300',
    },
    {
      title: 'Te presentamos a tu acompañante',
      description: 'Completado',
      status: 'status--success',
      completed: true,
      color: 'green300',
    },
    {
      title: 'Te invitamos al taller',
      description: 'Revisa catálogo',
      status: 'status--success',
      completed: true,
      color: 'green300',
    },
    {
      title: 'Seguimos en contacto',
      description: 'Completado',
      status: 'status--success',
      completed: true,
      color: 'green300',
    },
    {
      title: 'Déjanos tu opinión',
      description: 'En proceso',
      status: 'status--process',
      completed: false,
      color: 'purple500',
    },

    // Status color
    //-- In progress status--process - purple500
    //-- In progress status--success - green300
    //-- In progress status neutral '' - gray600
  ];

  return (
    <>
      <HeaderShared user={user} />
      <section className="container module">
        <div className="body-row row">
          <div className="col-12">
            <div className="module-bottom40 row">
              <div className="col-12">
                {user.step === 0 ? null : (
                  <ProcessStatusComponent steps={steps} currentStepIndex={0} />
                )}
              </div>
            </div>
            <div className="module-bottom row">
              <div className="text-center col-12">
                <h3 className="mb-3 text-52 text-500">
                  Ha finalizado tu procedimiento
                </h3>
                {user.step === UserSteps.PENDING_EVALUATION ? (
                  <p className="text-24">¿Qué sigue?</p>
                ) : (
                  <p className="text-24">Gracias</p>
                )}
              </div>
              <div className="mx-auto text-center col-lg-6 col-12">
                <div className="d-flex justify-content-center">
                  <figure className="image-490">
                    <img
                      src="/assets/images/illustrations/cat.png"
                      alt="Confirmación"
                    />
                  </figure>
                </div>
                {user.step === UserSteps.PENDING_EVALUATION && (
                  <>
                    <h1 className="mb-3 text-40">Compártenos tu opinión</h1>
                    <p className="mb-4">
                      Tu opinión y tu perspectiva nos motiva a seguir creando
                      mejoras, para poder brindar una mejor experiencia en este
                      proceso
                    </p>
                    <Link to="/dejanos-tu-opinion" className="btn btn--type1">
                      Quiero dejar mi opinión
                    </Link>
                  </>
                )}
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <DonateComponent />
              </div>
            </div>
          </div>
        </div>
      </section>
      <FooterShared />
    </>
  );
};

export default FinalizarScreen;
